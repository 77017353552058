import React, { useState } from "react";
import "./Intro.scss";
import celesteLogo from "../../assets/icons/celeste-logo.svg";
import arrowRight from "../../assets/icons/arrow-right.svg"
import arrowLeft from "../../assets/icons/arrow-left.svg"

const Intro = ({ toAbout, toHome, toForm, previous }) => {
  const [leave, setLeave] = useState(0)
  const toAboutSlide = () => {
    setLeave(1);
    setTimeout(() => {
      toAbout();
    }, 450)
  }
  const toHomeSlide = () => {
    setLeave(1);
    setTimeout(() => {
      toHome();
    }, 450)
  }
  const toFormSlide = () => {
    setLeave(2);
    setTimeout(() => {
      toForm();
    }, 450)
  }
  return (
    <div className={
      previous > 3 && leave === 0
      ? "intro-container in-left" 
      : previous < 3 && leave === 0 
      ? "intro-container in-right"
      : leave === 1
      ? "intro-container out-right"
      : leave === 2
      ? "intro-container out-left"
      : null
      }>
      <div className="intro-content-left">
        <div></div>
        <div className="intro-content-left__bottom">
          <span onClick={toAboutSlide}>
            <p>About</p>
          </span>
        </div>
      </div>
      <div className="intro-content-center">
        <img onClick={toHomeSlide} src={celesteLogo} alt="Celeste" />
      </div>
      <div className="intro-content-right">
        <div className="intro-content-right__top">
          <div className="intro-content-right__top__back">
            <img onClick={toHomeSlide} src={arrowLeft} alt="Arrow Left" />
          </div>
          <div className="intro-content-right__top__create">
            <div onClick={toFormSlide} className="intro-content-right__top__create__btn">
              <span>
                <p>First, select up to two locations</p>
              </span>
              <img src={arrowRight} alt="arrow-right" />
            </div>
          </div>
          <div></div>
        </div>
        <div className="intro-content-right__bottom">
          <div className="intro-content-right__bottom__p">
            <p>You will automatically connect with our beacons installed in the selected locations, creating in real time a new digital sky, a space where borders do not exist and the air is pure.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
