import { useState } from "react";
import "./App.css";
// import Creating from "./components/Creating/Creating";

import Footer from "./components/Footer/Footer";
import Slider from "./components/Slider/Slider";
// import Form from "./components/Form/Form";
// import Intro from "./components/Intro/Intro";
// import About from "./components/About/About";
// import Home from "./components/Home/Home";
function App() {
  const [inAbout, setInAbout] = useState(false);
  return (
    <div className="App">
      <Slider setInAbout={setInAbout} />
      <Footer inAbout={inAbout} />
    </div>
  );
}

export default App;
