import React, { useState } from 'react';
import download from '../../assets/icons/download-experience.svg'
import celesteLogo from "../../assets/icons/celeste-logo.svg";
// import appleIcon from "../../assets/icons/apple-icon.svg";
// import windowsIcon from "../../assets/icons/windows-icon.svg";
import arrowLeft from "../../assets/icons/arrow-left.svg";
import arrowRight from "../../assets/icons/arrow-right.svg";
import celesteVideo from "../../assets/vid/celeste-video.mp4";
import celesteSkies from "../../assets/img/celeste-skies.png";
import door from "../../assets/icons/nft-collection-door.svg";
import './Home.scss';

const Home = ({ toAbout, toIntro, previous,setInAbout }) => {
  const [leave, setLeave] = useState(0);
    setInAbout(false);
  const toAboutSlide = () => {
    setLeave(1);
    setTimeout(() => {
      toAbout();
      setLeave(0)
    }, 450)
  }
  const toIntroSlide = () => {
    setLeave(2);
    setTimeout(() => {
      toIntro();
      setLeave(0)
    }, 450)
  }

  return (
    <div className={
      previous > 2 && leave === 0
        ? "home-container in-left"
        : previous === 2 && leave === 0
          ? "home-container"
          : previous < 2 && leave === 0
            ? "home-container in-right"
            : leave === 1
              ? "home-container out-right"
              : leave === 2
                ? "home-container out-left"
                : null
    }>
      <div className="home-content-left">
        <div className="home-content-left__top" style={{ position: 'relative' }}>
          <div className="home-content-left__top__cont">

            <div className="home-content-left__top__cont__vid">
              <video
                loop
                autoPlay
                muted
                playsInline
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <source src={celesteVideo} type="video/mp4" />
              </video>
            </div>
            <a href="https://drive.google.com/file/d/1O2EfaRdd2RxxRIDGNmZQcxaK4jxSIRn-/view?usp=sharing" target="_blank" rel="noopener noreferrer " aria-hidden="true" style={{ textDecoration: 'none' }}>
              <span className="home-content-left__top__span">
                CELESTE METADOME EXPERIENCE
                <img src={download} alt="download-celeste-experience" />
              </span>
            </a>
            <div>
              {/* <img className="home-content-left__top__img__apple" src={appleIcon} alt="download-celeste-experience-apple" />
              <img className="home-content-left__top__img__windows" src={windowsIcon} alt="download-celeste-experience-windows" /> */}
            </div>
          </div>
        </div>
        {/* <div className="home-content-left__line">
            <hr></hr>
          </div> */}
        <div className="home-content-left__bottom">
          <span onClick={toAboutSlide} className="home-content-left__bottom__span">
            <img src={arrowLeft} alt="arrow-left" />
            <p>About</p>
          </span>
        </div>
      </div>
      <div className="home-content-center">
        <img src={celesteLogo} alt="celeste-logo" />
      </div>
      <div className="home-content-right">
        <div className="home-content-right__top">
          <span onClick={toIntroSlide} className="home-content-right__top__span">
            <span>
              <p>Begin</p>
            </span>
            <img src={arrowRight} alt="arrow-right" />
          </span>
        </div>
        {/* <div className="home-content-right__line">
            <hr></hr>
          </div> */}
        <div className="home-content-right__bottom">
          <div className="home-content-right__bottom__cont">
            <a href="https://www.artpool.xyz/" target="_blank" rel="noopener noreferrer " aria-hidden="true" style={{ textDecoration: 'none' }}>
              <span className="home-content-right__bottom__span">
                NFT AI TIME LAPSE COLLECTION
                <img src={door} alt="door-right" />
              </span>
            </a>
            <p className="home-content-right__bottom__p1">25 NFTs generated by Artificial Intelligence</p>
            <p className="home-content-right__bottom__p2">Be part of the beacons' network with your own one</p>
            <div className="home-content-right__bottom__img">
              <img src={celesteSkies} alt="celeste skies" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home