import React from 'react';
import './Footer.scss';
import solimanLogo from '../../assets/icons/soliman-logo.svg';

const Footer = ({inAbout}) => {
  return (
    <div className={inAbout ? 'footer-container-about':"footer-container"}>
          <div className="footer-content">
            <p>Universal digital sky generator:</p>
            <a href="https://solimanlopez.com/" target="_blank" rel="noopener noreferrer " aria-hidden="true">

            <img src={solimanLogo} alt="soliman-logo" />
            </a>
            
          </div>
      </div>
  )
}

export default Footer;