import React, { useState } from 'react';
import './Creating.scss';
import celesteLogo from "../../assets/icons/celeste-logo.svg";
import arrowLeft from "../../assets/icons/arrow-left.svg";

const Creating = ({ toAbout, toHome, toForm, previous }) => {
  const [leave, setLeave] = useState(0);

  const toAboutSlide = () => {
    setLeave(1);
    setTimeout(() => {
      toAbout();
      setLeave(0)
    }, 450)
  }
  const toHomeSlide = () => {
    setLeave(1);
    setTimeout(() => {
      toHome();
      setLeave(0)
    }, 450)
  }
  const toFormSlide = () => {
    setLeave(1);
    setTimeout(() => {
      toForm();
      setLeave(0)
    }, 450)
  }
  return (
    <div className={
      previous > 5 && leave === 0
      ? "creating-container in-left" 
      : previous < 5 && leave === 0 
      ? "creating-container in-right"
      : leave === 1
      ? "creating-container out-right"
      : leave === 2
      ? "creating-container out-left"
      : null
      }>
      <div className="creating-content-left">
        <div></div>
        <div className="creating-content-left__bottom">
          <span onClick={toAboutSlide}>
            <p>About</p>
          </span>
        </div>
      </div>
      <div className="creating-content-center">
        <img onClick={toHomeSlide} src={celesteLogo} alt="Celeste" />
      </div>
      <div className="creating-content-right">
        <div className="creating-content-right__top">
          <div className="creating-content-right__top__back">
            <img onClick={toFormSlide} src={arrowLeft} alt="Arrow Left" />
          </div>
          <div className="creating-content-right__top__text">
            <div>
              <p>COMMING SOON...</p>
            </div>
          </div>
          <div style={{width: '10%'}}></div>
        </div>
        <div className="creating-content-right__bottom">
        </div>
      </div>
    </div>
  )
}

export default Creating