import React, { useState } from "react";
import celesteLogo from "../../assets/icons/celeste-logo.svg";
import arrowRight from "../../assets/icons/arrow-right.svg";
import imgBaliza from "../../assets/img/img-baliza-1.png";
import "./About.scss";

const About = ({ toHome, setInAbout }) => {
  const [leave, setLeave] = useState(false);
  setInAbout(true);

  const toHomeSlide = () => {
    setLeave(true);
    setTimeout(() => {

      toHome();
    }, 450)
  }
  return (

    <div className={leave ? `leave` : "about-container"}>
      <div className="about-content-left">
        <div className="about-content-left__top">
          <div className="about-content-left__top__pdiv">
            <p className="about-content-left__top__p">
              Through a beacon equipped with the necessary material to capture the
              image of the sky in real time, the system extracts the colours
              obtained and combines them in a new digital interface that can be
              visited at celeste.solimanlopez.com or through different types of
              IMMERSIVE installations.
            </p>
            <p className="about-content-left__top__p">
              During the night in any of the locations, the system goes into
              «sleep» mode, accessing a database of images of ideal skies mixed
              with colour alterations.
            </p>
            <p className="about-content-left__top__p">
              Solimán Lopez brings together the skies of different cities around
              the world and displays the colorimetric variations of these unions.
            </p>
          </div>
          <div onClick={toHomeSlide} className="about-content-left__center__btn">
            <span>
              <p>Home</p>
            </span>
            <img src={arrowRight} alt="arrow-right" />
          </div>
        </div>
        {/* <div className="about-content-left__center">
          <div onClick={toHomeSlide} className="about-content-left__center__btn">
            <span>
              <p>Home</p>
            </span>
            <img src={arrowRight} alt="arrow-right" />
          </div>
        </div> */}
        <div className="about-content-left__bottom">
          <div className="about-content-left__bottom__img">
            <img src={imgBaliza} alt="beacon" />
          </div>
          <div className="about-content-left__bottom__line">
            <div>
              <hr></hr>
            </div>
            <div>
              <p className="about-content-left__bottom__p">
                Celeste is installed in several contemporary arts centers such
                as Véles e Vents in Valencia, Spain, La Lonja Alicante, Spain,
                the Scan Room in London, UK as well as Mi Gallery in Paris.
              </p>
              <p className="about-content-left__bottom__p">
                The project is, in March 2020, the “icon” of the Spanish film
                festival in Bogota, Colombia,VIII Muestra de Cine Español (MCE)
                where the work now unites the skies of Madrid with several
                cities in Colombia. The beacons are placed at Casa America in
                Madrid, as well as at El Planetario in Bogotá, at the La
                Tertulia museum in Cali and at el Parque Explora in Medellín.
                The festival, due to the international Covid-19 epidemic, is
                postponed, but the work is still installed and exhibited on the
                web.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="about-content-center">
        <img onClick={toHomeSlide} src={celesteLogo} alt="celeste" />
      </div>
      <div className="about-content-right">
        <div></div>
        <div>
          <hr></hr>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default About;
