import React, { useState } from 'react';
import About from '../About/About.js';
import Home from '../Home/Home.js';
import Intro from '../Intro/Intro.js';
import Form from '../Form/Form.js';
import Creating from '../Creating/Creating.js';

const Slider = ({setInAbout}) => {
    const [page, setPage] = useState(2)
    const [previous, setPrevious] = useState(page);
    const toAbout = () => {
        setPrevious(page);
        return setPage(1);
    }
    const toHome = () => {
        setPrevious(page);
        return setPage(2);
    }
    const toIntro = () => {
        setPrevious(page);
        return setPage(3);
    }
    const toForm = () => {
        setPrevious(page);
        return setPage(4);
    }
    const toCreating = () => {
        setPrevious(page);
        return setPage(5);
    }
    switch (page) {
        case 1:
            return (
                <About toHome={toHome} setInAbout={setInAbout} />
            );
        case 2:
            return (
                <Home toAbout={toAbout} toIntro={toIntro} previous={previous} setInAbout={setInAbout} />
            );
        case 3:
            return (
                <Intro toAbout={toAbout} toHome={toHome} toForm={toForm} previous={previous}/>
            );
        case 4:
            return (
                <Form toAbout={toAbout} toHome={toHome} toIntro={toIntro} toCreating={toCreating} previous={previous}/>
            );
        case 5:
            return (
                <Creating toAbout={toAbout} toHome={toHome} toForm={toForm} previous={previous}  />
            );

        default:
            return (
                <Home toAbout={toAbout} toIntro={toIntro} previous={previous} setInAbout={setInAbout}  />
            );
    }
}

export default Slider