import React, { useState } from "react";
import "./Form.scss";
import celesteLogo from "../../assets/icons/celeste-logo.svg";
import arrowRight from "../../assets/icons/arrow-right.svg";
import arrowLeft from "../../assets/icons/arrow-left.svg";

const Form = ({ toAbout, toHome, toIntro, toCreating, previous }) => {
  const [leave, setLeave] = useState(0)
  const toAboutSlide = () => {
    setLeave(1);
    setTimeout(() => {
      toAbout();
      setLeave(0)
    }, 450)
  }
  const toIntroSlide = () => {
    setLeave(1);
    setTimeout(() => {
      toIntro();
      setLeave(0)
    }, 450)
  }
  const toHomeSlide = () => {
    setLeave(1);
    setTimeout(() => {
      toHome();
      setLeave(0)
    }, 450)
  }
  const toCreatingSlide = () => {
    setLeave(2);
    setTimeout(() => {
      toCreating();
      setLeave(0)
    }, 450)
  }
  return (
    <div className={
      previous > 4 && leave === 0
      ? "form-container in-left" 
      : previous < 4 && leave === 0 
      ? "form-container in-right"
      : leave === 1
      ? "form-container out-right"
      : leave === 2
      ? "form-container out-left"
      : null
      }>
      <div className="form-content-left">
        <div></div>
        <div className="form-content-left__bottom">
          <span onClick={toAboutSlide}>
            <p>About</p>
          </span>
        </div>
      </div>
      <div className="form-content-center">
        <img onClick={toHomeSlide} src={celesteLogo} alt="Celeste" />
      </div>
      <div className="form-content-right">
        <div className="form-content-right__form">
          <div className="form-content-right__form__top">
            <div className="form-content-right__form__top__back">
              <img onClick={toIntroSlide} src={arrowLeft} alt="Arrow Left" />
            </div>
            <div className="form-content-right__form__top__inputs">
              <label
                className="form-content-right__form__top__inputs__label1"
                htmlFor="rio"
                style={{display: 'none'}}
              >
                Rio du Janeiro / Museo du Amanhana
                <input
                  className="form-content-right__form__input"
                  type="checkbox"
                  id="rio"
                  name="rio"
                  value="Rio du Janeiro"
                  disabled
                />
                <span className="form-content-right__form__span"></span>
              </label>
              <label
                className="form-content-right__form__top__inputs__label2"
                htmlFor="lisboa"
              >
                Lisboa / Maat Museum
                <input
                  className="form-content-right__form__input"
                  type="checkbox"
                  id="lisboa"
                  name="lisboa"
                  value="Lisboa"
                  checked
                />
                <span className="form-content-right__form__span"></span>
              </label>
            </div>
            <div className="form-content-right__form__top__create">
              <div onClick={toCreatingSlide} className="form-content-right__form__top__create__btn">
                <span>
                  <p>Create digital sky metaverse</p>
                </span>
                <img src={arrowRight} alt="arrow-right" />
              </div>
            </div>
          </div>
          <div className="form-content-right__form__bottom">
            <label
              className="form-content-right__form__bottom__label1"
              htmlFor="barcelona"
              style={{display: 'none'}}
            >
              Barcelona / Espronceda Institute
              <input
                className="form-content-right__form__input"
                type="checkbox"
                id="barcelona"
                name="barcelona"
                value="Barcelona"
                disabled

              />
              <span className="form-content-right__form__span"></span>
            </label>
            <label
              className="form-content-right__form__bottom__label2"
              htmlFor="mallorca"
              style={{display: 'none'}}
            >
              Palma de Mallorca / Baró Gallery
              <input
                className="form-content-right__form__input"
                type="checkbox"
                id="mallorca"
                name="mallorca"
                value="Palma de Mallorca"
                disabled

              />
              <span className="form-content-right__form__span"></span>
            </label>
            <label
              className="form-content-right__form__bottom__label3"
              htmlFor="paris"
            >
              Paris / Artist Studio
              <input
                className="form-content-right__form__input"
                type="checkbox"
                id="paris"
                name="paris"
                value="Paris"
                disabled

              />
              <span className="form-content-right__form__span"></span>
            </label>
            <form action="mailto:intronsdev@gmail.com" method="post" enctype="text/plain">

              <label
                className="form-content-right__form__bottom__label4"
                htmlFor="your-place"
              >
                Your place
                <input
                  type="text"
                  id="yourPlace"
                  name="your-place"
                  style={{marginLeft: '10px', backgroundColor: 'black', fontFamily: 'Bungee Hairline', width: '8rem'}}
                />
                <input
                  className="form-content-right__form__input"
                  type="submit"
                  name="submit"
                  value="Thanks for sharing your location!"
                  style={{marginTop: '8px', marginLeft: '8px', height: '10px', width: '10px'}}
                />
                <span className="form-content-right__form__span"></span>
              </label>
              </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
